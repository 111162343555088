import { z } from 'zod';

export enum ExperienceStatus {
	UNKNOWN = 0,
	PLAYABLE = 1,
	GUEST_PROHIBITED = 2,
	UNAPPROVED = 3,
	PRIVATE = 5,
	UNDER_REVIEW = 9,
}

export interface ExperienceThumbnail {
	icon?: string;
	banner?: string;
}

export interface FullExperienceResponse {
	universe_id: number;
	place_id: number;
	name: string;
	genre: string;
	updated: string; // Date string
	created: string; // Date string
	released: string; // Date string
	canonical_name?: string; // Excluded if equal to name
	thumbnails?: ExperienceThumbnail;
	players_online?: number;
	total_visits?: number;
	like_percentage?: number;
	creator_id?: number;
	play_status: ExperienceStatus;
	genre_l1: string;
	genre_l2: string;
	daily_pick_dates?: string[]; // Date string array
	price?: number;
}

export interface SlimExperienceResponse {
	universe_id: number;
	place_id: number;
	name: string;
	canonical_name?: string; // Excluded if equal to name
	players_online?: number;
	total_visits?: number;
	like_percentage?: number;
	creator_id?: number;
}

export const ExperienceThumbnailSchema: z.ZodType<ExperienceThumbnail> =
	z.object({
		icon: z.string().optional(),
		banner: z.string().optional(),
	});

export const FullExperienceResponseSchema: z.ZodType<FullExperienceResponse> =
	z.object({
		universe_id: z.number(),
		place_id: z.number(),
		name: z.string(),
		genre: z.string(),
		updated: z.string(),
		created: z.string(),
		released: z.string(),
		canonical_name: z.string().optional(),
		thumbnails: ExperienceThumbnailSchema.optional(),
		players_online: z.number().optional(),
		total_visits: z.number().optional(),
		like_percentage: z.number().optional(),
		age_limit: z.number().nullable(),
		play_status: z.nativeEnum(ExperienceStatus),
		creator_id: z.number().optional(),
		genre_l1: z.string(),
		genre_l2: z.string(),
		daily_pick_dates: z.array(z.string()).optional(),
		price: z.number().optional(),
	});

export const SlimExperienceResponseSchema: z.ZodType<SlimExperienceResponse> =
	z.object({
		universe_id: z.number(),
		place_id: z.number(),
		name: z.string(),
		canonical_name: z.string().optional(),
		players_online: z.number().optional(),
		total_visits: z.number().optional(),
		like_percentage: z.number().optional(),
		creator_id: z.number().optional(),
	});

export const SlimExperienceListSchema = z.array(SlimExperienceResponseSchema);

export interface FullExperience
	extends Omit<
		FullExperienceResponse,
		| 'updated'
		| 'created'
		| 'released'
		| 'players_online'
		| 'total_visits'
		| 'like_percentage'
		| 'creator'
		| 'canonical_name'
		| 'creator_id'
		| 'daily_pick_dates'
		| 'price'
	> {
	updated: Date;
	created: Date;
	released: Date;
	players_online: number;
	total_visits: number;
	like_percentage: number;
	age_limit?: number;
	play_status: ExperienceStatus;
	rank?: number; // FE only
	canonical_name: string;
	creator_id: number;
	daily_pick_dates: Date[];
	price: number;
}

export interface SlimExperience
	extends Omit<
		FullExperienceResponse,
		| 'updated'
		| 'created'
		| 'released'
		| 'play_status'
		| 'creator'
		| 'players_online'
		| 'total_visits'
		| 'like_percentage'
		| 'genre'
		| 'thumbnails'
		| 'canonical_name'
		| 'creator_id'
		| 'genre_l1'
		| 'genre_l2'
		| 'daily_pick_dates'
	> {
	players_online: number;
	total_visits: number;
	like_percentage: number;
	thumbnails?: ExperienceThumbnail;
	rank?: number; // FE only
	canonical_name: string;
	creator_id: number;
}

export type Experience = SlimExperience | FullExperience;
