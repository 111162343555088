import { observer } from 'mobx-react-lite';
import {
	ExperienceStatus,
	FullExperience,
} from '@/schemas/experience-schema.ts';
import Button from '@components/core/button/button.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ExpansionButton from '@components/core/expansion-button/expansion-button.tsx';
import Icon from '@components/core/icon/icon.tsx';
import copyService from '@services/copy-service.ts';
import { useNavigate } from 'react-router-dom';

interface ExperienceDetailsOptionsProps {
	experience: FullExperience;
	moveLuanchToDropdown?: boolean;
}

const ExperienceDetailsOptions = observer(function ExperienceDetailsOptions(
	props: ExperienceDetailsOptionsProps
) {
	const navigate = useNavigate();

	return (
		<div
			className={
				'h-full flex flex-col justify-center items-center gap-8 min-w-experience-thumbnail max-w-experience-thumbnail'
			}
		>
			{!props.moveLuanchToDropdown && (
				<Button
					title={'Launch Experience'}
					icon={{
						icon: IconEnum.PLAY,
						placement: 'right',
						size: '1.5em',
					}}
					onClick={() => {
						open(
							`roblox://placeId=${props.experience.place_id}`,
							'_blank'
						);
					}}
					disabled={
						props.experience.play_status !==
						ExperienceStatus.PLAYABLE
					}
				/>
			)}

			<ExpansionButton
				title={'Experience Options'}
				subMenuButtons={[
					...(props.moveLuanchToDropdown &&
					props.experience.play_status === ExperienceStatus.PLAYABLE
						? [
								{
									title: (
										<div
											className={
												'flex justify-center items-center gap-2 px-4'
											}
										>
											{'Launch Experience'}
											<Icon
												icon={IconEnum.PLAY}
												size={'1.5em'}
											/>
										</div>
									),
									onClick: () => {
										open(
											`roblox://placeId=${props.experience.place_id}`,
											'_blank'
										);
									},
								},
							]
						: []),
					{
						title: (
							<div
								className={
									'flex justify-center items-center gap-2 px-4'
								}
							>
								{'Compare To Experience'}
								<Icon icon={IconEnum.COMPARE} size={'1.5rem'} />
							</div>
						),
						onClick: () => {
							navigate(
								`/compare/experiences#exp=${props.experience.place_id}`
							);
						},
					},
					{
						title: (
							<div
								className={
									'flex justify-center items-center gap-2 px-4'
								}
							>
								{'Compare To Tag'}
								<Icon icon={IconEnum.COMPARE} size={'1.5rem'} />
							</div>
						),
						onClick: () => {
							navigate(
								`/compare/experience-tag#exp=${props.experience.place_id}`
							);
						},
					},
					{
						title: (
							<div
								className={
									'flex justify-center items-center gap-2 px-4 group/yt w-full h-full'
								}
							>
								{'Find on YouTube'}
								<div
									className={
										'flex justify-center items-center'
									}
								>
									<Icon
										icon={IconEnum.VIDEO}
										size={'1.5rem'}
									/>
								</div>
							</div>
						),
						onClick: () => {
							open(
								`https://www.youtube.com/results?search_query=roblox+${props.experience.canonical_name.toLowerCase().replaceAll(' ', '+')}`,
								'_blank'
							);
						},
					},
					{
						title: (
							<div
								className={
									'flex justify-center items-center gap-2 px-4'
								}
							>
								{'Copy Experience Id'}
								<Icon icon={IconEnum.COPY} />
							</div>
						),
						onClick: () => {
							copyService.experienceId(props.experience);
						},
					},
					{
						title: (
							<div
								className={
									'flex justify-center items-center gap-2 px-4'
								}
							>
								{'Copy Universe Id'}
								<Icon icon={IconEnum.COPY} />
							</div>
						),
						onClick: () => {
							copyService.experienceUniverseId(props.experience);
						},
					},
				]}
			/>
		</div>
	);
});

export default ExperienceDetailsOptions;
