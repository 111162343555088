export enum LocalStorageKey {
	NavSidebarManuOptionSelected = `tgs-sb-m`,
	NavSidebarManuOptionOpen = `tgs-nbm-o`,
	TableSize = 'table-size',
	USDExchangeRates = 'gang-usd-rates',
	ColorTheme = 'theme',
	ExperienceTagTabSelected = 'tgs-et-dt',
	CCUToggleState = 'tgs-c-c-u-t-s',
}

export type LocalStorageKeyType =
	| `${string}-${LocalStorageKey.TableSize}`
	| `${LocalStorageKey.NavSidebarManuOptionOpen}-${string}`
	| `${LocalStorageKey.CCUToggleState}-${string}`
	| `${LocalStorageKey}`;
