import { observer } from 'mobx-react-lite';
import EditableText from '@components/core/editable-text/editable-text.tsx';
import {
	ExperienceStatus,
	FullExperience,
} from '@/schemas/experience-schema.ts';
import experienceStore from '@store/experience-store.ts';
import formatNumberWithSuffix from '@utils/format-number-with-suffix.ts';
import ExperienceCreator from '@components/data/experience-creator/experience-creator.tsx';
import ExperienceDetailsInfoPanel from '@views/experience-details/view-components/experience-details-info-panel.tsx';
import PlaceThumbnail from '@components/data/place-thumbnail/place-thumbnail.tsx';
import { PlaceThumbnailSize } from '@components/data/place-thumbnail/place-thumbnail-constants.ts';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import Icon from '@components/core/icon/icon.tsx';
import { Link, NavLink } from 'react-router-dom';
import PlaceBanner from '@components/data/place-banner/place-banner.tsx';
import { PlaceBannerSize } from '@components/data/place-banner/place-banner-constants.ts';
import timeAgo from '@utils/time-ago.ts';
import authStore from '@store/auth-store.ts';
import { UserPermission, UserRole } from '@/schemas/user-schema.ts';
import IsValidDate from '@utils/is-valid-date.ts';
import DatePicker from '@components/core/date-picker/date-picker.tsx';
import themeStore from '@store/theme-store.ts';

interface ExperienceDetailsInfoProps {
	experience: FullExperience;
	className?: string;
}

const million = 1e6;

const robloxPlaceUrl = 'https://www.roblox.com/games/';

const ageLimit = (limit?: number) => {
	if (limit === 0) {
		return 'All Ages';
	}

	if (!limit) {
		return 'Not Rated';
	}

	return `${limit}+`;
};

const status = (status: ExperienceStatus) => {
	switch (status) {
		case ExperienceStatus.UNKNOWN:
			return 'Unknown';
		case ExperienceStatus.PRIVATE:
		case ExperienceStatus.GUEST_PROHIBITED:
			return 'Private';
		case ExperienceStatus.UNAPPROVED:
			return 'Unapproved';
		case ExperienceStatus.PLAYABLE:
			return 'Playable';
		case ExperienceStatus.UNDER_REVIEW:
			return 'Under Review';
	}
};

const ExperienceDetailsInfo = observer(function ExperienceDetailsInfo(
	props: ExperienceDetailsInfoProps
) {
	const handleCanonicalNameChance = (newName: string) => {
		if (props.experience.canonical_name !== newName) {
			void experienceStore.updateCanonicalName(props.experience, newName);
		}
	};

	const handleReleaseDateChange = (value: string) => {
		if (!props.experience) {
			return;
		}

		let newDate: Date;

		if (value && IsValidDate(value)) {
			newDate = new Date(value);
		} else {
			newDate = new Date('0001-01-01T00:00:00Z');
		}

		if (newDate.getTime() !== props.experience.released.getTime()) {
			void experienceStore.updateReleaseDate(props.experience, newDate);
		}
	};

	const canEdit = authStore.havePermission(UserPermission.EDIT_PLACE);

	return (
		<div
			className={
				'flex flex-col xl:flex-row items-center justify-between gap-6'
			}
		>
			<Link
				to={`${robloxPlaceUrl}${props.experience.place_id}`}
				target={'_blank'}
				className={
					'rounded-lg border border-gray-300 dark:border-gray-500 hover:border-blue-400 shadow-lg items-center bg-white dark:bg-gray-550 cursor-pointer hover:bg-blue-500 hover:text-white overflow-hidden xl:min-w-experience-thumbnail xl:max-w-experience-thumbnail'
				}
			>
				<PlaceThumbnail
					place={props.experience}
					size={PlaceThumbnailSize.VERY_LARGE}
					className={
						'min-w-experience-thumbnail max-w-experience-thumbnail hidden xl:inline-block'
					}
				/>

				<PlaceBanner
					place={props.experience}
					size={PlaceBannerSize.MEDIUM}
					className={'inline-block xl:hidden'}
				/>

				<div
					className={
						'flex justify-center items-center text-lg py-2 w-full'
					}
				>
					<div className={'mr-2'}>View on roblox.com</div>

					<Icon icon={IconEnum.EXTERNAL_LINK} size={'1.5rem'} />
				</div>
			</Link>

			<div
				className={
					'flex justify-center xl:justify-center items-start content-center flex-wrap gap-4 flex-grow'
				}
			>
				<div className={'w-full grid grid-cols-1 lg:grid-cols-2 gap-4'}>
					<ExperienceDetailsInfoPanel
						title={'Canonical Name'}
						content={
							canEdit ? (
								<EditableText
									text={props.experience.canonical_name}
									onTextChange={handleCanonicalNameChance}
									truncate
									noLeftPadding
								/>
							) : (
								props.experience.canonical_name
							)
						}
						className={'!w-full !max-w-full'}
					/>

					<ExperienceDetailsInfoPanel
						title={'Creator'}
						content={
							<NavLink
								to={`/creators/${props.experience.creator_id}`}
								className={
									'hover:text-blue-500 hover:dark:text-blue-200 hover:underline inline-block max-w-fit'
								}
							>
								<ExperienceCreator
									creatorId={props.experience.creator_id}
								/>
							</NavLink>
						}
						className={'!w-full !max-w-full'}
					/>
				</div>

				<div className={'w-full grid grid-cols-2 lg:grid-cols-3 gap-4'}>
					<ExperienceDetailsInfoPanel
						title={'Players Online'}
						content={new Intl.NumberFormat(
							navigator.language
						).format(props.experience.players_online)}
						className={'!w-full !max-w-full'}
					/>

					<ExperienceDetailsInfoPanel
						title={'Visits'}
						content={
							props.experience.total_visits >= million
								? `${formatNumberWithSuffix(
										props.experience.total_visits
									)}+`
								: new Intl.NumberFormat(
										navigator.language
									).format(props.experience.total_visits)
						}
						className={'!w-full !max-w-full'}
						tooltip={
							props.experience.total_visits >= million
								? new Intl.NumberFormat(
										navigator.language
									).format(props.experience.total_visits)
								: undefined
						}
						disableTruncate={
							props.experience.total_visits >= million
						}
					/>

					<ExperienceDetailsInfoPanel
						title={'Like Percent'}
						content={`${(props.experience.like_percentage * 100).toFixed(2)}%`}
						className={'!w-full !max-w-full'}
					/>

					<ExperienceDetailsInfoPanel
						title={'Genre'}
						content={
							props.experience.genre_l1
								? `${props.experience.genre_l1} ${props.experience.genre_l2 ? `- ${props.experience.genre_l2}` : ''}`
								: 'N/A'
						}
						className={'!w-full !max-w-full'}
					/>

					<ExperienceDetailsInfoPanel
						title={'Age Limit'}
						content={ageLimit(props.experience.age_limit)}
						disableTruncate
						className={'!w-full !max-w-full'}
					/>

					<ExperienceDetailsInfoPanel
						title={'Updated'}
						content={timeAgo(props.experience.updated)}
						disableTruncate
						className={'!w-full !max-w-full'}
					/>
				</div>

				<div className={'w-full grid grid-cols-2 lg:grid-cols-3 gap-4'}>
					<ExperienceDetailsInfoPanel
						title={'Status'}
						content={status(props.experience.play_status)}
						disableTruncate
						className={'!w-full !max-w-full'}
					/>

					<ExperienceDetailsInfoPanel
						title={'Price'}
						content={
							props.experience.price ? (
								<div
									className={
										'flex justify-start items-center gap-1'
									}
								>
									{props.experience.price}{' '}
									<img
										src={
											themeStore.lightTheme
												? '/robux-black.svg'
												: '/robux-white.svg'
										}
										alt={''}
										className={'w-6 h-6'}
									/>
								</div>
							) : (
								'Free'
							)
						}
						disableTruncate
						className={'!w-full !max-w-full'}
					/>

					<ExperienceDetailsInfoPanel
						title={'Release Date'}
						content={
							authStore.haveUserRole([
								UserRole.EDITOR,
								UserRole.ADMIN,
							]) ? (
								<DatePicker
									value={
										// Non set release dates are set to year 1
										props.experience.released.getFullYear() <
										2
											? '-'
											: props.experience.released.toLocaleDateString()
									}
									onChange={handleReleaseDateChange}
								/>
							) : props.experience.released.getFullYear() < 2 ? (
								'-'
							) : (
								props.experience.released.toLocaleDateString()
							)
						}
						className={'!w-full !max-w-full'}
					/>
				</div>
			</div>
		</div>
	);
});

export default ExperienceDetailsInfo;
