import { z } from 'zod';

export interface RobuxPriceHistoryResponse {
	price: number;
	time: string; // Date string
	change: number;
}

export const RobuxPriceHistorySchema: z.ZodType<RobuxPriceHistoryResponse> =
	z.object({
		price: z.number(),
		time: z.string(),
		change: z.number(),
	});

export const RobuxPriceHistoryListSchema = z.array(RobuxPriceHistorySchema);

export interface RobuxPriceHistory
	extends Omit<RobuxPriceHistoryResponse, 'time'> {
	time: Date;
}
