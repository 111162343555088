import { makeAutoObservable, runInAction } from 'mobx';
import httpFetch from '@services/http-fetch-service.ts';
import {
	RobuxPriceHistory,
	RobuxPriceHistoryListSchema,
} from '@/schemas/robux-price-history.ts';

class RobuxPriceHistoryStore {
	private _experienceRobuxPriceHistory: Record<number, RobuxPriceHistory[]> =
		{};

	constructor() {
		makeAutoObservable(this);
	}

	async loadExperienceRobuxPriceHistory(experienceId: number) {
		if (this._experienceRobuxPriceHistory[experienceId]) {
			return;
		}

		const experienceRobuxHistoryApi = `${import.meta.env.VITE_SERVER_URL}/api/place/${experienceId}/price`;
		const response = await httpFetch.GET(experienceRobuxHistoryApi);

		if (response.ok) {
			const loadedHistory = RobuxPriceHistoryListSchema.parse(
				await response.json()
			);
			const parsedHistory = loadedHistory.map((history) => {
				return { ...history, time: new Date(history.time) };
			});
			runInAction(() => {
				this._experienceRobuxPriceHistory = {
					...this._experienceRobuxPriceHistory,
					[experienceId]: parsedHistory,
				};
			});
		} else {
			runInAction(() => {
				this._experienceRobuxPriceHistory = {
					...this._experienceRobuxPriceHistory,
					[experienceId]: [],
				};
			});
		}
	}

	experienceRobuxPriceHistory(experienceId: number) {
		return this._experienceRobuxPriceHistory[experienceId] ?? [];
	}
}

const robuxPriceHistoryStore = new RobuxPriceHistoryStore();
export default robuxPriceHistoryStore;
