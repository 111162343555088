import { observer } from 'mobx-react-lite';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { NavigationButtonEnum } from '@components/navigation/navigation-button/navigation-button-enum.ts';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ClassString from '@utils/class-string.ts';
import { ReactNode } from 'react';

export interface LocationState {
	from: {
		pathname: string;
		search: string;
	};
}

interface NavigationButtonProps {
	path: string;
	direction?: NavigationButtonEnum;
	title?: string;
	external?: boolean;
	outline?: boolean;
	background?: boolean;
	onClick?: () => unknown;
	children?: ReactNode;
	className?: string;
}

const baseUrl = import.meta.env.VITE_BASE_URL as string;

const NavigationButton = observer(function NavigationButton(
	props: NavigationButtonProps
) {
	const location = useLocation();
	const navigate = useNavigate();

	return (
		<Link
			className={ClassString({
				static: 'group rounded-button h-button flex items-center text-gray-600 dark:text-white hover:shadow-md max-w-full hover:bg-blue-500 hover:text-white',
				dynamic: {
					'px-5 -mx-5': !!props.title || !!props.children,
					'border-none': !props.outline,
					'm-0 shadow-md border border-gray-600 dark:border-gray-500 hover:border-blue-500 dark:hover:border-blue-500':
						props.outline,
					'bg-transparent': !props.background,
					'bg-white dark:bg-gray-525 dark:text-white':
						props.background,
				},
				custom: props.className,
			})}
			to={props.external ? props.path : `${baseUrl}${props.path}`}
			target={props.external ? '_blank' : ''}
			state={{ from: location }}
			onClick={(e) => {
				if (props.onClick) {
					props.onClick();
				}

				if (!props.path) {
					e.preventDefault();
					navigate(-1);
				}
			}}
		>
			{props.direction === NavigationButtonEnum.BACK && (
				<Icon
					icon={IconEnum.ARROW_BACK}
					className={ClassString({
						static: 'min-w-5',
						dynamic: {
							'mr-2 opacity-0 group-hover:opacity-100':
								!!props.title || !!props.children,
							'opacity-100': props.outline,
						},
					})}
				/>
			)}

			{props.children}

			<div className={'truncate'}>{props.title && props.title}</div>

			{props.direction === NavigationButtonEnum.FORWARD && (
				<Icon
					icon={IconEnum.ARROW_FORWARD}
					className={ClassString({
						static: 'min-w-5',
						dynamic: {
							'transform scale-0 ml-0 opacity-0 group-hover:opacity-100 group-hover:scale-100 group-hover:ml-2':
								!!props.title || !!props.children,
							'opacity-100': props.outline,
						},
					})}
					size={'1.25em'}
				/>
			)}

			{props.direction === NavigationButtonEnum.EXTERNAL && (
				<Icon
					icon={IconEnum.EXTERNAL_LINK}
					className={ClassString({
						static: 'min-w-5',
						dynamic: {
							'transform scale-0 ml-0 opacity-0 group-hover:opacity-100 group-hover:scale-100 group-hover:ml-2':
								!!props.title || !!props.children,
							'opacity-100': props.outline,
						},
					})}
					size={'1.25em'}
				/>
			)}
		</Link>
	);
});

export default NavigationButton;
