import { ReactElement } from 'react';
import { makeAutoObservable, runInAction } from 'mobx';

interface ModalOptions {
	overflow: boolean;
	clickOutSideToClose: boolean;
	className: string;
	darkerBackdrop: boolean;
}

type OptionalModalOptions = Partial<ModalOptions>;

class ModalStore {
	private defaultOptions: ModalOptions = {
		overflow: false,
		clickOutSideToClose: true,
		className: '',
		darkerBackdrop: false,
	};

	// Store more complex elements like a Highcharts graph as a function
	private activeModal: (() => ReactElement) | ReactElement | undefined;
	private activeOptions: ModalOptions = { ...this.defaultOptions };

	constructor() {
		makeAutoObservable(this);
	}

	private resetOptions() {
		runInAction(() => {
			this.activeOptions = { ...this.defaultOptions };
		});
	}

	open(
		content: (() => ReactElement) | ReactElement,
		options?: OptionalModalOptions
	) {
		runInAction(() => {
			this.activeModal = content;
			this.activeOptions = { ...this.activeOptions, ...options };
		});
	}

	close() {
		runInAction(() => {
			this.activeModal = undefined;
		});
		this.resetOptions();
	}

	get content() {
		return typeof this.activeModal === 'function'
			? this.activeModal()
			: this.activeModal;
	}

	get visible() {
		return !!this.activeModal;
	}

	get options() {
		return this.activeOptions;
	}
}

const modalStore = new ModalStore();
export default modalStore;
